
    import Vue from 'vue';

    import RCP from '@/api/RCP.ts';

    import SelectField from '@/components/SelectField.vue';
    import { Version, VersionResponse } from '@/types/Version';
    import { ErrorResponse } from '@/types/Errors';

    export default Vue.extend({
        name: 'templateSettings',
        data() {
            return {
                properties: {
                    formats: {
                        A4: 'A4',
                        A5: 'A5'
                    },
                    orientations: {
                        book: 'Книжная',
                        landscape: 'Альбомная'
                    },
                    file: {},
                    text: {}
                },
                formWatch: {
                    form: {
                        page_property: {
                            format: '',
                            orientation: ''
                        },
                        generator_text: '',
                        generator_file: ''
                    },
                    file_out_formats: {} as any
                },
                request: false,
                textList: {},
                fileList: {},
                textMap: new Map(),
                fileMap: new Map(),
                routeCheck: false,
                watcherId: () => false as any,
                error: false
            };
        },
        created() {
            this.initialize();
        },
        computed: {
            currentVersion(): Version {
                return this.$store.state.template.version;
            }
        },
        watch: {
            'textList'(value) {
                this.properties.text = {};

                value.forEach((item: any) => {
                    this.$set(this.properties.text, item.code, item.Generator.name);
                    this.textMap.set(item.code, item);
                });

                this.formWatch.form.generator_text = this.currentVersion.generator_text;
            },
            'fileList'(value) {
                this.properties.file = {};

                value.forEach((item: any) => {
                    this.$set(this.properties.file, item.code, item.Generator.name);
                    this.fileMap.set(item.code, item);
                });

                this.formWatch.form.generator_file = this.currentVersion.generator_file;
            },
            'formWatch.form.generator_file'(value) {
                this.formWatch.file_out_formats = {};

                const generator = this.fileMap.get(value);
                const formats = (generator.out_format && generator.out_format.length > 0)
                    ? generator.out_format
                    : generator.Generator.out_format;
                const active = this.currentVersion.generator_file_out_format || [];

                formats.forEach((item: any) => {
                    this.$set(this.formWatch.file_out_formats, item, active.includes(item));
                });
            }
        },
        methods: {
            initialize() {
                this.getOptions().then(this.setForm);
            },
            getOptions() {
                return new Promise((resolve, reject) => {
                    this.error = false;

                    RCP([
                        {
                            method: 'App.Generator.Text.list',
                            id: 'GenText',
                            params: {
                                select: ['*', 'Generator']
                            }
                        },
                        {
                            method: 'App.Generator.File.list',
                            id: 'GenFile',
                            params: {
                                select: ['*', 'Generator']
                            }
                        }
                    ]).then((result: any) => {
                        if (result.length === 2) {
                            this.textList = result[0].items;
                            this.fileList = result[1].items;
                        } else {
                            this.error = true;
                        }

                        setTimeout(resolve, 0);
                    }).catch((error: ErrorResponse) => {
                        this.error = true;

                        if (error.show) {
                            alert('Ошибка : ' + error.message);
                        }

                        reject();
                    });
                });
            },
            setForm() {
                this.routeCheck = false;
                this.watcherId();
                this.watcherId = () => false;

                if (this.currentVersion.page_property) {
                    this.formWatch.form.page_property.format = this.currentVersion.page_property.format;
                    this.formWatch.form.page_property.orientation = this.currentVersion.page_property.orientation;
                }

                this.watcherId = this.$watch('formWatch', () => {
                    this.routeCheck = true;
                    this.watcherId();
                }, {
                    deep: true
                });
            },
            saveVersion(flag = false) {
                return new Promise((resolve, reject) => {
                    if (!this.request) {
                        this.request = true;

                        const fileOutFormats: any = [];

                        Object.keys(this.formWatch.file_out_formats).forEach((index) => {
                            if (this.formWatch.file_out_formats[index]) {
                                fileOutFormats.push(index);
                            }
                        });

                        const attr: any = {
                            app_id: this.currentVersion.app_id,
                            template_id: this.currentVersion.template_id,
                            generator_file_out_format: [],
                            ...this.formWatch.form
                        };

                        if (fileOutFormats.length > 0) {
                            attr.generator_file_out_format = fileOutFormats;
                        }

                        RCP({
                            method: 'App.Template.Version.set',
                            params: {
                                attr
                            },
                            id: 'saveVersion'
                        }).then((result: VersionResponse) => {
                            if (result.item) {
                                this.$store.commit('template/setVersion', result.item);
                                this.setForm();

                                if (!flag) {
                                    alert('Сохранено : Настройки по шаблону успешно сохранены');
                                }

                                resolve();
                            } else {
                                alert('Ошибка : Не удалось обновить настроки шаблона');
                                reject();
                            }

                            this.request = false;
                        }).catch((error: ErrorResponse) => {
                            if (error.show) {
                                alert('Ошибка : ' + error.message);
                            }

                            this.request = false;
                            reject();
                        });
                    } else {
                        reject();
                    }
                });
            },
            checkChanges() {
                return new Promise((resolve) => {

                    if (this.routeCheck) {
                        if (!confirm("Внесены изменения: Сохранить внесенные изменения перед переходом на другую страницу?")) {
                                resolve();
                         }else{
                            return this.saveVersion(true).then(() => {
                                    resolve();
                                }).catch(() => {
                                    alert( 'Произошла ошибка');
                                    
                                });
                        }

                    } else {
                        resolve();
                    }
                });
            }
        },
        beforeRouteLeave(to: any, from: any, next: any) {
            this.checkChanges().then(next);
        },
        components: {
            SelectField
        }
    });
